import 'intersection-observer'
import VMain from './components/VMain.vue'
import VCaption from './components/VCaption.vue'
import VCover from './components/VCover.vue'
import VFigure from './components/VFigure.vue'
import VFooter from './components/VFooter.vue'
import VHeader from './components/VHeader.vue'
import VHero from './components/VHero.vue'
import VMedia from './components/VMedia.vue'
import VVideoInstance from './components/VVideoInstance.vue'
import VNavigation from './components/VNavigation.vue'
import VSlideshow from './components/VSlideshow.vue'
import VTitle from './components/VTitle.vue'
import VStripes from './components/VStripes.vue'
import VCoverSlideshow from './components/VCoverSlideshow.vue'
import VDyptique from './components/VDyptique.vue'
import VDual from './components/VDual.vue'
import Vspace from './components/VSpace.vue'

export default {
  install(Vue) {
    [
      VMain,
      VStripes,
      VTitle,
      VCaption,
      VCover,
      VFigure,
      VFooter,
      VHeader,
      VHero,
      VMedia,
      VVideoInstance,
      VNavigation,
      VCoverSlideshow,
      VDyptique,
      VSlideshow,
      Vspace,
      VDual
    ].forEach(component => Vue.component(component.name, component))
  }
}
