<template>
  <div
    class="cover"
    :class="{
      'cover--fit': ratio === -1,
      'cover--gutters': gutters,
      'cover--innerspace': innerspace
    }"
  >
    <v-media
      class="cover__media"
      :src="src"
      :lazy="lazy"
      :srcset="srcset"
      :sizes="sizes"
      :ratio="ratio"
      :poster="poster"
      :muteDisplay="muteDisplay"
      :media-link="mediaLink"
    />
  </div>
</template>

<script>
import VMedia from './VMedia.vue'

export default {
  name: 'VCover',

  components: {
    VMedia
  },

  props: {
    lazy: {
      type: String,
      required: false,
      default: 'lazy'
    },
    src: {
      type: String,
      required: true
    },
    srcset: {
      type: String,
      default: ''
    },
    sizes: {
      type: String,
      default: ''
    },
    ratio: {
      type: Number,
      default: 0
    },
    poster: {
      type: String,
      default: ''
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    gutters: {
      type: Boolean,
      default: false
    },
    innerspace: {
      type: Boolean,
      default: false
    },
    mediaLink: {
      type: String,
      default: ''
    },
    muteDisplay : {
      type: Boolean,
      default: true
    },
  }
}
</script>

<style lang="sass">
.cover
  // margin: var(--innergutter) 0
  position: relative

  &--innerspace
    padding: 0 calc(var(--gutter))
    @media screen and (min-width: 768px)
      padding: 0 calc(var(--gutter) * 3)

  &--gutters
    padding: 0 var(--gutter)
    .media__wrapper
      margin: 0 

  &__media
    width: 100%

  &--fit &__media
    height: 100%
</style>
